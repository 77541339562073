import { FormattedMessage } from "react-intl";
import step1 from "../../../../assets/images/how_it_works/1-min.svg";
import step2 from "../../../../assets/images/how_it_works/2-min.svg";
import step3 from "../../../../assets/images/how_it_works/3-min.svg";
import step4 from "../../../../assets/images/how_it_works/4-min.svg";
import step5 from "../../../../assets/images/how_it_works/5-min.svg";
import step6 from "../../../../assets/images/how_it_works/three-steps.png";

const Steps = () => {
  return (
    <div className="how_it_works_steps_wrapper bg_image_2">
      <div
        className="bg_blur"
        style={{
          padding: "2em",
          background: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <div
          className="services_banner_container services_banner_container_desktop service_steps_desktop "
          id="personalized-strategy"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">1</div>
              <div>
                <FormattedMessage id="step1_one" defaultMessage="Sign up " />
                <a href="/getting-started" style={{ color: "inherit" }}>
                  <FormattedMessage id="step1_two" defaultMessage="here" />
                </a>
                <FormattedMessage
                  id="step1_three"
                  defaultMessage=" to get access to Freeze Carbon"
                />
              </div>
            </div>
          </div>

          <div className="service_banner_image service_image_desktop">
            <img src={step1} className="image_width" />
          </div>
        </div>

        <div
          className="services_banner_container services_banner_container_mobile"
          id="personalized-strategy"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">1</div>
              <div>
                <FormattedMessage id="step1_one" defaultMessage="Sign up " />
                <a href="/getting-started" style={{ color: "inherit" }}>
                  <FormattedMessage id="step1_two" defaultMessage="here" />
                </a>
                <FormattedMessage
                  id="step1_three"
                  defaultMessage=" to get access to Freeze Carbon"
                />
              </div>
            </div>
          </div>

          <div className="service_banner_image">
            <img src={step1} className="service_image_width" />
          </div>
        </div>

        <div
          className="services_banner_container row-reverse services_banner_container_desktop  service_steps_reverse_desktop"
          id="quality-analysis"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">2</div>
              <FormattedMessage
                id="step2"
                defaultMessage="View and compare the best carbon projects on the planet"
                values={{ break: <br /> }}
              />
            </div>
          </div>

          <div className="service_banner_image_reverse service_image_desktop">
            <img src={step2} className="image_width" />
          </div>
        </div>

        {/* step 2 Mobile version  */}
        <div
          className="services_banner_container services_banner_container_mobile"
          id="quality-analysis"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">2</div>
              <FormattedMessage
                id="step2"
                defaultMessage="View and compare the best carbon projects on the planet"
                values={{ break: <br /> }}
              />
            </div>
          </div>

          <div className="service_banner_image_reverse">
            <img src={step2} className="service_image_width" />
          </div>
        </div>

        {/* steps 3 */}

        <div
          className="services_banner_container services_banner_container_desktop service_steps_desktop"
          id="personalized-strategy"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">3</div>
              <FormattedMessage
                id="step3"
                defaultMessage="Sign up here to get access to Freeze Carbon"
              />
            </div>
          </div>

          <div className="service_banner_image">
            <img src={step3} className="image_width" />
          </div>
        </div>

        {/* step 3 banner mobile */}

        <div
          className="services_banner_container services_banner_container_mobile"
          id="personalized-strategy"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">3</div>
              <FormattedMessage
                id="step3"
                defaultMessage="Sign up here to get access to Freeze Carbon"
              />
            </div>
          </div>

          <div className="service_banner_image">
            <img src={step3} className="service_image_width" />
          </div>
        </div>

        {/* step 4 */}

        <div
          className="services_banner_container row-reverse services_banner_container_desktop service_steps_reverse_desktop"
          id="quality-analysis"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">4</div>
              <FormattedMessage
                id="step4"
                defaultMessage="View and compare the best carbon projects on the planet"
                values={{ break: <br /> }}
              />
            </div>
          </div>

          <div className="service_banner_image_reverse service_steps_image_width">
            <img src={step4} className="image_width" />
          </div>
        </div>

        {/* step 4 Mobile version  */}
        <div
          className="services_banner_container services_banner_container_mobile"
          id="quality-analysis"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">4</div>
              <FormattedMessage
                id="step4"
                defaultMessage="View and compare the best carbon projects on the planet"
                values={{ break: <br /> }}
              />
            </div>
          </div>

          <div className="service_banner_image_reverse">
            <img
              src={step4}
              className="service_image_width service_steps_image_width"
            />
          </div>
        </div>

        {/* steps 5  */}

        <div
          className="services_banner_container services_banner_container_desktop service_steps_desktop"
          id="personalized-strategy"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">5</div>
              <FormattedMessage
                id="step5"
                defaultMessage="Sign up here to get access to Freeze Carbon"
              />
            </div>
          </div>

          <div className="service_banner_image service_banner_steps_image_width">
            <img src={step5} className="service_steps_image_width" />
          </div>
        </div>

        {/* step 1 banner mobile */}

        <div
          className="services_banner_container services_banner_container_mobile"
          id="personalized-strategy"
        >
          <div className="service_banner_data service_steps_data_desktop">
            <div className="service_banner_heading service_steps_banner_heading">
              <div className="circle">5</div>
              <FormattedMessage
                id="step5"
                defaultMessage="Sign up here to get access to Freeze Carbon"
              />
            </div>
          </div>

          <div className="service_banner_image">
            <img src={step5} className="service_image_width" />
          </div>
        </div>

        <div
          className="homepage_project_container_action center"
          style={{ marginTop: "3em", marginBottom: "3em" }}
        >
          <a
            href="#register-free-account"
            className="homepage_appbar_nav_get_started"
          >
            <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Steps;
