import React, { useEffect } from "react";
import NavBar from "../../components/NavBar";
import { Link as RouterLink } from "react-router-dom";
import biochar from "../../../../assets/images/new-icons/biochar.svg";
import erw from "../../../../assets/images/new-icons/erw.svg";
import bb from "../../../../assets/images/new-icons/bb.svg";
import dac from "../../../../assets/images/new-icons/dac.svg";
import ee from "../../../../assets/images/new-icons/ee.svg";
import rh from "../../../../assets/images/new-icons/rh.svg";
import mg from "../../../../assets/images/new-icons/mg.svg";
import om from "../../../../assets/images/new-icons/om.svg";
import sdw from "../../../../assets/images/new-icons/sdw.svg";
import gs from "../../../../assets/images/new-icons/gs.svg";
import iso from "../../../../assets/images/new-icons/iso.svg";
import dnv from "../../../../assets/images/new-icons/dnv.svg";
import pe from "../../../../assets/images/new-icons/pe.svg";
import ebc from "../../../../assets/images/new-icons/csi.png";
import gp1 from "../../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../../assets/images/new-icons/gp6.svg";
import pccs from "../../../../assets/images/new-icons/pccs.svg";
import qarm from "../../../../assets/images/new-icons/qarm.svg";
import cep from "../../../../assets/images/new-icons/cep.svg";
import Icon from "@mui/material/Icon";

import logo from "assets/images/logo.png";
import Footer from "../../components/Footer";
import { FormattedMessage } from "react-intl";
import Partners from "../../components/Partners";
import ClientScroller from "ui-component/ClientScroller";

import Steps from "./Steps";

const Services = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, []);
  return (
    <div>
      <NavBar />

      <div className="content_wrapper">
        <div className="homepage_step_project_header_container">
          <div className="homepage_project_heading navbar_margin_zero">
            <div className="homepage_project_heading_content">
              <FormattedMessage id="firstSteps" defaultMessage="First steps" />
            </div>
          </div>
        </div>

        <Steps />

        <div style={{ marginBottom: "3em" }}>
          <ClientScroller />
        </div>

        <div
          className="homepage_project_header_container"
          style={{ marginBottom: "3em" }}
        >
          <div className="homepage_project_heading navbar_margin_zero">
            <div className="homepage_project_heading_content">
              <FormattedMessage
                id="end_to_end"
                defaultMessage="End-to-end service"
              />
            </div>
          </div>
        </div>

        {/* services banner */}

        <div
          className="services_banner_container services_banner_container_spacing services_banner_container_desktop "
          id="personalized-strategy"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading">
              <FormattedMessage
                id="personalized_carbon_credit_strategy"
                defaultMessage="Personalized carbon credit strategy"
              />
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                <FormattedMessage
                  id="service1_desc1"
                  defaultMessage="Our experts tailor a portfolio of projects matching your ESG goals (e.g. SBTi, TÜV), company DNA, industry and supply chain."
                />
              </span>
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                <FormattedMessage
                  id="service1_desc2"
                  defaultMessage="Get inspired by our pre-build portfolios or custom design one with our experts."
                />
              </span>
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                <FormattedMessage
                  id="service1_desc3"
                  defaultMessage="We support your climate contribution ambition or accelerate your journey towards net-zero emissions."
                />
              </span>
            </div>
          </div>

          <div className="service_banner_image">
            <img src={pccs} className="service_image_width" />
          </div>
        </div>

        {/* services banner mobile */}

        <div
          className="services_banner_container services_banner_container_spacing services_banner_container_mobile"
          id="personalized-strategy"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading">
              <FormattedMessage
                id="personalized_carbon_credit_strategy"
                defaultMessage="Personalized carbon credit strategy"
              />
            </div>
          </div>

          <div className="service_banner_image">
            <img src={pccs} className="service_image_width" />
          </div>

          <div className="bullet-point-item">
            {/* <span className="bullet-marker">•</span> */}
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              <FormattedMessage
                id="service1_desc1"
                defaultMessage="Our experts tailor a portfolio of projects matching your ESG goals (e.g. SBTi, TÜV), company DNA, industry and supply chain."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            {/* <span className="bullet-marker">•</span> */}
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              {/* <span className="bullet-text"> */}
              <FormattedMessage
                id="service1_desc2"
                defaultMessage="Get inspired by our pre-build portfolios or custom design one with our experts."
              />
            </span>
          </div>
        </div>

        {/* services banner */}

        <div
          className="services_banner_container services_banner_container_spacing row-reverse services_banner_container_desktop "
          id="quality-analysis"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading">
              <FormattedMessage
                id="quality_analysis_risk_mitigation"
                defaultMessage="Quality analysis{break} & risk mitigation"
                values={{ break: <br /> }}
              />
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span> */}
              {/* <span className="bullet-text"> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                {" "}
                <FormattedMessage
                  id="service2_desc1"
                  defaultMessage="Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze."
                />
              </span>
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span>
              <span className="bullet-text"> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                <FormattedMessage
                  id="service2_desc2"
                  defaultMessage="Ongoing monitoring and tracking of each project."
                />
              </span>
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span>
              <span className="bullet-text"> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                <FormattedMessage
                  id="service2_desc3"
                  defaultMessage="Comprehensive 5-page report on each project, providing you with all details to make an informed purchase"
                />
              </span>
            </div>
          </div>

          <div className="service_banner_image_reverse">
            <img src={qarm} className="service_image_width" />
          </div>
        </div>

        {/* Mobile version of "Quality Analysis & Risk Mitigation" banner */}
        <div
          className="services_banner_container services_banner_container_spacing services_banner_container_mobile"
          id="quality-analysis"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading">
              <FormattedMessage
                id="quality_analysis_risk_mitigation"
                defaultMessage="Quality analysis{break} & risk mitigation"
                values={{ break: <br /> }}
              />
            </div>
          </div>

          <div className="service_banner_image_reverse">
            <img src={qarm} className="service_image_width" />
          </div>
          <div className="bullet-point-item">
            {/* <span className="bullet-marker">•</span>
            <span className="bullet-text"> */}
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              <FormattedMessage
                id="service2_desc1"
                defaultMessage="Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            {/* <span className="bullet-marker">•</span>
            <span className="bullet-text"> */}
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              <FormattedMessage
                id="service2_desc2"
                defaultMessage="Ongoing monitoring and tracking of each project."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            {/* <span className="bullet-marker">•</span>
            <span className="bullet-text"> */}
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              <FormattedMessage
                id="service2_desc3"
                defaultMessage="Comprehensive 5-page report on each project, providing you with all details to make an informed purchase"
              />
            </span>
          </div>
          <div className="service_banner_button">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started"
            >
              <FormattedMessage id="getStarted" defaultMessage="Sign up" />
            </RouterLink>
          </div>
        </div>

        {/* services banner */}

        <div
          className="services_banner_container services_banner_container_spacing services_banner_container_desktop"
          id="cost-effective"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading">
              <FormattedMessage
                id="cost_effective_procurement"
                defaultMessage="Cost-effective{break} procurement"
                values={{ break: <br /> }}
              />
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span>
              <span className="bullet-text"> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                {" "}
                <FormattedMessage
                  id="service3_desc1"
                  defaultMessage="Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place."
                />
              </span>
            </div>
            <div className="bullet-point-item">
              {/* <span className="bullet-marker">•</span> */}
              <span className="bullet-text" style={{ marginBottom: "16px" }}>
                {" "}
                <FormattedMessage
                  id="service3_desc2"
                  defaultMessage="Utilize secure payment options, acting as an escrow service."
                />
              </span>
            </div>
            <div className="service_banner_button">
              <RouterLink
                to="/getting-started"
                className="homepage_appbar_nav_get_started"
              >
                <FormattedMessage
                  id="exploreProjects"
                  defaultMessage="Start now"
                />
              </RouterLink>
            </div>
          </div>

          <div className="service_banner_image">
            <img src={cep} className="service_image_width" />
          </div>
        </div>

        {/* Mobile version of "Cost-effective Procurement" banner */}
        <div
          className="services_banner_container services_banner_container_spacing services_banner_container_mobile"
          id="cost-effective"
        >
          <div className="service_banner_data">
            <div className="service_banner_heading">
              <FormattedMessage
                id="cost_effective_procurement"
                defaultMessage="Cost-effective{break} procurement"
                values={{ break: <br /> }}
              />
            </div>
          </div>

          <div className="service_banner_image">
            <img src={cep} className="service_image_width" />
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              <FormattedMessage
                id="service3_desc1"
                defaultMessage="Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            {/* <span className="bullet-marker">•</span> */}
            <span className="bullet-text" style={{ marginBottom: "16px" }}>
              <FormattedMessage
                id="service3_desc2"
                defaultMessage="Utilize secure payment options, acting as an escrow service."
              />
            </span>
          </div>
          <div className="service_banner_button">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started"
            >
              <FormattedMessage id="getStarted" defaultMessage="Sign up" />
            </RouterLink>
          </div>
        </div>

        <div style={{ marginBottom: "3em" }}>
          <Partners />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Services;
