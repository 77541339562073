import removal from "assets/images/icons/removal_icon.svg";
import biochar from "assets/images/icons/biochar.svg";
import biogas from "assets/images/project_types_new/biogas_household.svg";
import dac from "assets/images/project_types_new/dac.svg";
import ebc from "assets/images/certification/ebc.svg";
import goal13 from "assets/images/social_dev/E_WEB_13.png";
import avoidance from "assets/images/icons/avoidance_icon.svg";
import gold_standard from "assets/images/certification/640px-The_Gold_Standard_logo.svg.png";

import goal2 from "assets/images/social_dev/E_WEB_02.png";
import goal7 from "assets/images/social_dev/E_WEB_07.png";
import goal8 from "assets/images/social_dev/E_WEB_08.png";
// import goal9 from "assets/images/social_dev/E_WEB_09.png";
import goal10 from "assets/images/social_dev/E_WEB_10.png";
import goal11 from "assets/images/social_dev/E_WEB_11.png";
import goal12 from "assets/images/social_dev/E_WEB_12.png";
// import goal13 from "assets/images/social_dev/E_WEB_13.png";
import goal14 from "assets/images/social_dev/E_WEB_14.png";
import goal15 from "assets/images/social_dev/E_WEB_15.png";
import goal16 from "assets/images/social_dev/E_WEB_16.png";
import goal17 from "assets/images/social_dev/E_WEB_17.png";
import puro from "assets/images/certification/puro.webp";
import goal9 from "assets/images/social_dev/E_WEB_09.png";

import goal1 from "assets/images/social_dev/E_WEB_01.png";
// import goal2 from "assets/images/social_dev/E_WEB_02.png";
import goal3 from "assets/images/social_dev/E_WEB_03.png";
import goal4 from "assets/images/social_dev/E_WEB_04.png";
import goal5 from "assets/images/social_dev/E_WEB_05.png";
import goal6 from "assets/images/social_dev/E_WEB_06.png";
import isometric from "assets/images/certification/isometric.png";
import erw from "assets/images/project_types_new/erw.svg";

export const projectData = [
  {
    titleId: "project_one",
    titleDefaultMessage: "Brothers recycling biowaste",
    code: "DE",
    imageSrc:
      "https://storage.googleapis.com/freezecarbon-dev-public/website_static/projects/1.jpg",

    subSections: [
      {
        classMessageId: "project_class",
        classDefaultMessage: "Project class",
        messageId: "carbon_removal",
        messageDefault: "Carbon removal",
        icon: [removal],
        iconWidth: "40px",
      },
      {
        classMessageId: "project_type",
        classDefaultMessage: "Project type",
        messageId: "biochar",
        messageDefault: "Biochar",
        icon: [biochar],
        iconWidth: "40px",
      },
      {
        classMessageId: "certification_standard",
        classDefaultMessage: "Certification standard",
        icon: [ebc],
        iconWidth: "150px",
      },
      {
        classMessageId: "sdgs",
        classDefaultMessage: "SDGs",
        icon: [goal13],
        iconWidth: "40px",
      },
    ],
  },
  {
    titleId: "project_two",
    titleDefaultMessage:
      "Turning animal manure into biogas / Methane avoidance",
    code: "IN",
    imageSrc:
      "https://storage.googleapis.com/freezecarbon-dev-public/website_static/projects/2.jpg",

    subSections: [
      {
        classMessageId: "project_class",
        classDefaultMessage: "Project class",
        messageId: "carbon_removal",
        messageDefault: "Carbon removal",
        icon: [removal],
        iconWidth: "40px",
      },
      {
        classMessageId: "project_type",
        classDefaultMessage: "Project type",
        messageId: "biochar",
        messageDefault: "Biochar",
        icon: [biochar],
        iconWidth: "40px",
      },
      {
        classMessageId: "certification_standard",
        classDefaultMessage: "Certification standard",
        icon: [gold_standard],
        iconWidth: "100px",
      },
      {
        classMessageId: "sdgs",
        classDefaultMessage: "SDGs",
        icon: [goal12, goal13, goal15],
        iconWidth: "40px",
      },
    ],
  },
  {
    titleId: "project_three",
    titleDefaultMessage:
      "Direct Air Capture by leveraging existing infrastructure",
    code: "DE",
    imageSrc:
      "https://storage.googleapis.com/freezecarbon-dev-public/website_static/projects/3.jpg",

    subSections: [
      {
        classMessageId: "project_class",
        classDefaultMessage: "Project class",
        messageId: "carbon_removal",
        messageDefault: "Carbon removal",
        icon: [removal],
        iconWidth: "40px",
      },
      {
        classMessageId: "project_type",
        classDefaultMessage: "Project type",
        messageId: "dac",
        messageDefault: "Direct Air Capture",
        icon: [dac],
        iconWidth: "40px",
      },
      {
        classMessageId: "certification_standard",
        classDefaultMessage: "Certification standard",
        icon: [puro],
        iconWidth: "100px",
      },
      {
        classMessageId: "sdgs",
        classDefaultMessage: "SDGs",
        icon: [goal9, goal13],
        iconWidth: "40px",
      },
    ],
  },
  {
    titleId: "project_four",
    titleDefaultMessage: "Direct Air Capture in Industriegebieten",
    code: "BR",
    imageSrc:
      "https://storage.googleapis.com/freezecarbon-dev-public/website_static/projects/4.jpg",

    subSections: [
      {
        classMessageId: "project_class",
        classDefaultMessage: "Project class",
        messageId: "carbon_removal",
        messageDefault: "Carbon removal",
        icon: [removal],
        iconWidth: "40px",
      },
      {
        classMessageId: "project_type",
        classDefaultMessage: "Project type",
        messageId: "erwShort",
        messageDefault: "Enhanced Rock Weathering",
        icon: [erw],
        iconWidth: "40px",
      },
      {
        classMessageId: "certification_standard",
        classDefaultMessage: "Certification standard",
        icon: [isometric],
        iconWidth: "100px",
      },
      {
        classMessageId: "sdgs",
        classDefaultMessage: "SDGs",
        icon: [goal1, goal2, goal3],
        iconWidth: "40px",
      },
    ],
  },

  {
    titleId: "project_five",
    titleDefaultMessage: "Direct Air Capture in Industriegebieten",
    code: "KH",
    imageSrc:
      "https://storage.googleapis.com/freezecarbon-dev-public/website_static/projects/5.jpg",

    subSections: [
      {
        classMessageId: "project_class",
        classDefaultMessage: "Project class",
        messageId: "carbon_avoidance",
        messageDefault: "carbon_removal",
        icon: [avoidance],
        iconWidth: "40px",
      },
      {
        classMessageId: "project_type",
        classDefaultMessage: "Project type",
        messageId: "biogas",
        messageDefault: "biogas",
        icon: [biogas],
        iconWidth: "40px",
      },
      {
        classMessageId: "certification_standard",
        classDefaultMessage: "Certification standard",
        icon: [isometric],
        iconWidth: "100px",
      },
      {
        classMessageId: "sdgs",
        classDefaultMessage: "SDGs",
        icon: [goal2, goal7, goal13],
        iconWidth: "40px",
      },
    ],
  },

  {
    titleId: "project_six",
    titleDefaultMessage: "Direct Air Capture in Industriegebieten",
    code: "AR",
    imageSrc:
      "https://storage.googleapis.com/freezecarbon-dev-public/website_static/projects/6.jpg",

    subSections: [
      {
        classMessageId: "project_class",
        classDefaultMessage: "Project class",
        messageId: "carbon_removal",
        messageDefault: "Carbon removal",
        icon: [removal],
        iconWidth: "40px",
      },
      {
        classMessageId: "project_type",
        classDefaultMessage: "Project type",
        messageId: "landfill_gas",
        messageDefault: "Enhanced Rock Weathering",
        icon: [erw],
        iconWidth: "40px",
      },
      {
        classMessageId: "certification_standard",
        classDefaultMessage: "Certification standard",
        icon: [isometric],
        iconWidth: "100px",
      },
      {
        classMessageId: "sdgs",
        classDefaultMessage: "SDGs",
        icon: [goal7, goal8, goal13],
        iconWidth: "40px",
      },
    ],
  },
];
