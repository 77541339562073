import { FormattedMessage } from "react-intl";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import enpulse from "../../../../assets/images/clients/enpulse.png";
import vfu from "../../../../assets/images/clients/vfu.png";
import robin from "../../../../assets/images/clients/robin.jpeg";
import laxmi from "../../../../assets/images/clients/laxmi.jpg";
import race from "../../../../assets/images/clients/race.png";
import philip from "../../../../assets/images/clients/dkv_phiplip.jpeg";
import dkv from "../../../../assets/images/clients/dkv.png";

const References = () => {
  return (
    <div
      className="mission_projects_home_references homepage_references"
      style={{ marginTop: 0 }}
    >
      <div
        className="homepage_project_heading"
        style={{ width: "fit-content", marginBottom: "3em", marginTop: 0 }}
      >
        <div className="homepage_project_heading_content">
          {" "}
          <FormattedMessage id="customers" defaultMessage="Principles" />
        </div>
      </div>

      <div
        className="testimonial_home_reference_reviews_box"
        style={{ marginBottom: "2em" }}
      >
        <div className="homepage_project_container_types_row testimonial_reviews_box_child">
          <div className="homepage_project_container_types_row_column testimonial_review_box">
            <div className="homepage_project_container_types_row_column_heading2">
              <div className="testimonial_reviews_box_icons">
                <FormatQuoteIcon
                  fontSize="large"
                  className="testimonial_reviews_box_quote"
                />
                <img src={dkv} style={{ height: "80px" }} />
              </div>

              <div>
                “
                <FormattedMessage
                  id="customer5_data"
                  defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                />
                ”
              </div>
            </div>
            <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
              <div className="testimonial_client_photo">
                <img
                  src={philip}
                  style={{
                    height: "80px",
                    borderRadius: "50%",
                    border: "1px solid #0b496b",
                  }}
                />
              </div>
              <div className="testimonial_client_data">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer5_desc"
                    defaultMessage="Robin Knapp"
                  />
                </div>
                <FormattedMessage
                  id="customer5_title"
                  defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                  values={{ break: <br /> }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="homepage_project_container_types_row testimonial_reviews_box_child">
          <div className="homepage_project_container_types_row_column testimonial_review_box">
            <div className="homepage_project_container_types_row_column_heading2">
              <div className="testimonial_reviews_box_icons">
                <FormatQuoteIcon
                  fontSize="large"
                  className="testimonial_reviews_box_quote"
                />
                <img src={enpulse} style={{ height: "80px" }} />
              </div>

              <div>
                “
                <FormattedMessage
                  id="customer1_data"
                  defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                />
                ”
              </div>
            </div>
            <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
              <div className="testimonial_client_photo">
                <img
                  src={robin}
                  style={{
                    height: "80px",
                    borderRadius: "50%",
                    border: "1px solid #0b496b",
                  }}
                />
              </div>
              <div className="testimonial_client_data">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer1_desc"
                    defaultMessage="Robin Knapp"
                  />
                </div>
                <FormattedMessage
                  id="customer1_title"
                  defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                  values={{ break: <br /> }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="homepage_project_container_types_row testimonial_reviews_box_child">
          <div className="homepage_project_container_types_row_column testimonial_review_box">
            <div className="homepage_project_container_types_row_column_heading2 ">
              <div className="testimonial_reviews_box_icons">
                <FormatQuoteIcon
                  fontSize="large"
                  className="testimonial_reviews_box_quote"
                />
                <img src={race} style={{ height: "60px" }} />
              </div>

              <div>
                {" "}
                “
                <FormattedMessage
                  id="customer6_data"
                  defaultMessage="Credibility and quality play a critical role in the market for carbon credits. Carbon Freeze is the “Honest Marketplace” here and offers a platform on which companies can find and invest in high-quality projects."
                />
                “
              </div>
            </div>

            <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
              <div className="testimonial_client_photo">
                <img
                  src={laxmi}
                  style={{
                    height: "80px",
                    borderRadius: "50%",
                    width: "80px",
                    objectFit: "cover",
                    border: "1px solid #0b496b",
                  }}
                />
              </div>
              <div className="testimonial_client_data">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer6_desc"
                    defaultMessage="Henrik Ohlsen"
                  />
                </div>

                <FormattedMessage
                  id="customer6_title"
                  defaultMessage="Managing Director,{break} VfU"
                  values={{ break: <br /> }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="homepage_project_container_action center"
        // style={{ margin: "2em 0 1em 0" }}
      >
        <a
          href="#register-free-account"
          className="homepage_appbar_nav_get_started"
        >
          <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
        </a>
      </div>
    </div>
  );
};

export default References;
