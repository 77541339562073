import React from "react";
import { FormattedMessage } from "react-intl";

import {
  IconArrowDown,
  IconArrowRight,
  IconHeartHandshake,
  IconPlus,
} from "@tabler/icons";

const Hero = () => {
  return (
    <div className="homepage_hero_container">
      <video autoPlay loop muted className="homepage_hero_container_video">
        <source src="https://storage.googleapis.com/freezecarbon-website/hero_new.mp4" />{" "}
        Your browser does not support the video tag.
      </video>

      <div className="homepage_hero_textbox">
        <div className="homepage_hero_section_title">
          <div className="homepage_hero_heading">
            <FormattedMessage
              id="heroHeading"
              defaultMessage="Quality & Simplicity in Carbon Credits"
            />
          </div>
          <div className="homepage_hero_text">
            {" "}
            <FormattedMessage
              id="heroDesc"
              defaultMessage="Freeze Carbon curates carbon projects matching your culture and DNA. Deep quality analysis and transparent assessment to mitigate risks."
            />
          </div>
        </div>
        <div>
          {" "}
          <div className="bullet-point-item" style={{ margin: "0" }}>
            <span className="bullet-marker" style={{ color: "white" }}>
              •
            </span>
            <span
              className="bullet-text"
              style={{
                color: "#FFFFFF",
                fontSize: "1.2em",
                opacity: "1",
              }}
            >
              <FormattedMessage
                id="header_bullet1"
                defaultMessage="Cost-efficient procurement"
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker" style={{ color: "white" }}>
              •
            </span>
            <span
              className="bullet-text"
              style={{
                color: "#FFFFFF",
                fontSize: "1.2em",
                opacity: "1",
              }}
            >
              <FormattedMessage
                id="header_bullet2"
                defaultMessage="Complete transparency"
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker" style={{ color: "white" }}>
              •
            </span>
            <span
              className="bullet-text"
              style={{
                color: "#FFFFFF",
                fontSize: "1.2em",
                opacity: "1",
              }}
            >
              <FormattedMessage
                id="header_bullet3"
                defaultMessage="Projects matching your company's DNA"
              />
            </span>
          </div>
        </div>
        <div className="heropage_hero_action">
          <a
            href="#register-free-account"
            className="homepage_appbar_nav_get_started"
          >
            <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
            <IconArrowRight color="white" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
