import { FormattedMessage } from "react-intl";
import {
  IconArrowDown,
  IconHeartHandshake,
  IconPlus,
  IconChessBishop,
  IconTicTac,
} from "@tabler/icons";

const MissionProjects = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="homepage_project_heading"
          style={{ width: "fit-content", marginBottom: "3em" }}
        >
          <div className="homepage_project_heading_content">
            {" "}
            <FormattedMessage id="servicesNew" defaultMessage="Principles" />
          </div>
        </div>
      </div>
      <div
        className="homepage_project_heading_data_content"
        style={{
          margin: "0.3em 0",
        }}
      >
        <div className="homepage_project_heading_data_content_value">
          <FormattedMessage
            id="solutions_text"
            defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
            values={{ break: <br /> }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
          style={{ marginBottom: "2em", textTransform: "none" }}
        >
          <FormattedMessage
            id="services_sub_heading"
            defaultMessage="Protect your reputation and contribute to global cooling"
          />
        </div>
      </div>

      <div className="mission_home_projects_priciples about_section_1">
        <div className="mission_home_projects_priciples_box">
          <div className="mission_home_projects_priciples_box_image">
            <IconTicTac width={50} height={50} />
          </div>
          <div className="mission_home_projects_priciples_box_heading">
            <FormattedMessage id="solution1" defaultMessage="Together" />
          </div>
          <div className="mission_home_projects_priciples_box_data">
            <FormattedMessage
              id="solution1_desc"
              defaultMessage="Only as a collective of market participants, companies and teammates can we make the impossible possible."
            />
          </div>
        </div>
        <div className="mission_home_projects_priciples_box">
          <div className="mission_home_projects_priciples_box_image">
            <IconPlus width={50} height={50} />{" "}
          </div>
          <div className="mission_home_projects_priciples_box_heading">
            <FormattedMessage id="solution2" defaultMessage="Simple" />
          </div>
          <div className="mission_home_projects_priciples_box_data">
            <FormattedMessage
              id="solution2_desc"
              defaultMessage="We break down the very complex topic of carbon credits into easily digestible pieces."
            />{" "}
          </div>
        </div>
        <div className="mission_home_projects_priciples_box">
          <div className="mission_home_projects_priciples_box_image">
            <IconHeartHandshake width={50} height={50} />{" "}
          </div>
          <div className="mission_home_projects_priciples_box_heading">
            {" "}
            <FormattedMessage id="solution3" defaultMessage="transparent" />
          </div>
          <div className="mission_home_projects_priciples_box_data">
            <FormattedMessage
              id="solution3_desc"
              defaultMessage="We take a very close look at carbon projects so that we understand all the details and can communicate them openly to our customers."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionProjects;
