import { FormattedMessage } from "react-intl";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { Button } from "@mui/material";
import { IconArrowUpCircle } from "@tabler/icons";

import Project from "./OurProjects/Project";
import { projectData } from "../../../../common";

const ExampleProjects = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2CA4E6",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#E0C2FF",
        light: "#F5EBFF",
        contrastText: "#47008F",
      },
    },
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div className="homepage__example_project_heading">
          <div className="homepage_project_heading_content">
            {" "}
            <FormattedMessage
              id="exampleProjects"
              defaultMessage="Example Projects"
            />
          </div>
        </div>
      </div>

      <div>
        <div
          className="homepage_project_container container_spacing"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div className="homepage_project_container_types projects_gap">
            {projectData.slice(0, 3).map((project, index) => (
              <Project
                key={index} // Use a unique key (like project ID if available)
                setDisplayModalCallBack={() => setDisplayModal(!displayModal)}
                data={project} // Pass project dynamically
              />
            ))}
          </div>

          <div className={`Modal ${displayModal ? "Show" : ""}`}>
            <div className="modal_container">
              <div className="modeal_container_content">
                <div className="modeal_container_content_text">
                  <div className="modal_container_arrow">
                    <IconArrowUpCircle size="30" />{" "}
                    <div>
                      {" "}
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreArrow"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </div>
                  </div>

                  <div className="learnMoreHeadingStyle">
                    {" "}
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreHeading"
                      defaultMessage="Safe and efficient carbon neutralization"
                    />{" "}
                  </div>
                </div>
                <div className="modeal_container_content_button">
                  <div className="learn_more_data">
                    {" "}
                    <FormattedMessage
                      values={{ break: <br /> }}
                      id="learnMoreData"
                      defaultMessage="Take action to cool our planet today"
                    />{" "}
                  </div>
                  <ThemeProvider theme={theme}>
                    <Button
                      variant="contained"
                      href="/getting-started#talk-to-expert"
                      style={{
                        minWidth: "320px",
                      }}
                    >
                      {" "}
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreButton1"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="/getting-started#talk-to-expert"
                      style={{
                        minWidth: "320px",
                      }}
                    >
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreButton2"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </Button>
                  </ThemeProvider>
                </div>
                <button
                  className="Close"
                  onClick={() => setDisplayModal(!displayModal)}
                >
                  X
                </button>
              </div>
            </div>
          </div>
          <div
            className={`Overlay ${displayModal ? "Show" : ""}`}
            onClick={() => setDisplayModal(!displayModal)}
          />
        </div>
      </div>
    </div>
  );
};

export default ExampleProjects;
