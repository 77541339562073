import React from "react";
import { FormattedMessage } from "react-intl";

import gs from "../../../../assets/images/new-icons/gs.svg";
import iso from "../../../../assets/images/new-icons/iso.svg";
import dnv from "../../../../assets/images/new-icons/dnv.svg";
import pe from "../../../../assets/images/new-icons/pe.svg";
import ebc from "../../../../assets/images/new-icons/csi.png";

const Standards = () => {
  return (
    <div className="homepage_certification_logo1 bg_image_2 container_spacing">
      <div className="homepage_certification_logo1_frame118 bg_blur">
        <div className="homepage_certification_logo1_frame118_heading">
          <FormattedMessage
            id="certifiers_we_partner_with"
            defaultMessage="Certifiers we partner with"
          />
        </div>
        <div className="homepage_certification_logo1_frame118_content">
          <div className="homepage_certification_logo1_frame118_row">
            <img src={gs} />
          </div>
          <div className="homepage_certification_logo1_frame118_row">
            <img src={iso} />
          </div>
          <div className="homepage_certification_logo1_frame118_row">
            <img src={dnv} />
          </div>
          <div className="homepage_certification_logo1_frame118_row">
            <img src={pe} />
          </div>
          <div className="homepage_certification_logo1_frame118_row">
            <img src={ebc} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Standards;
