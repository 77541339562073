import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MeetingsEmbed from "../components/MeetingsEmbed";
import { FormattedMessage } from "react-intl";
import ViewsContactLong from "views/hub-spot/contact-long";
import { useLocation } from "react-router-dom";
import ClientScroller from "ui-component/ClientScroller";
import Partners from "../components/Partners";
import ThorstenCard from "ui-component/ThorstenCard";

const GettingStarted = () => {
  return (
    <div className="getting_started_main">
      <NavBar />

      <div className="content_wrapper">
        <div className="getting_started_boxes ">
          <section
            className="getting_started_box getting-started-form"
            id="register-free-account"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              marginBottom: "3em",
            }}
          >
            <div className="getting_started_box_content"></div>

            <div className="getting_started_contact_form">
              <ViewsContactLong />
            </div>
            <div className="homepage_project_heading_data_content homepage_project_container">
              <div className="homepage_project_heading_data_content_value ">
                <FormattedMessage
                  id="gettingStartedHeader"
                  defaultMessage="Sign up to get access to our carbon projects."
                  values={{ break: <br /> }}
                />
                {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
              </div>
            </div>
          </section>

          <ClientScroller />

          <ThorstenCard />
          <div className="getting_started_custom">
            <Partners />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default GettingStarted;
