import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import {
  MuiTelInput,
  MuiTelInputContinent,
  MuiTelInputCountry,
  MuiTelInputFlagElement,
} from "mui-tel-input";

import { auth } from "../firebase"; // Your Firebase config file
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import { getEnvironmentConstants } from "utils/constants";
import { GenericBackend } from "GenericBackend";

const SignUpForm: React.FC = () => {
  // Form State
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationType, setRegistrationType] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const continents: MuiTelInputContinent[] = ["EU"];
  const excludedCountries: MuiTelInputCountry[] = ["FR"];
  // It's also the return value for the `getFlagElement` function prop
  const unknownFlag: MuiTelInputFlagElement = <img src=".." />;

  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Reset the error to empty, so that resubmission should not show previous error
    setErrorMessage("");

    // Basic validation
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    if (!acceptTerms) {
      setErrorMessage("Please accept the terms and conditions");
      return;
    }

    // Basic phone number length validation
    const phoneDigits = phoneNumber.replace(/[^\d]/g, ""); // Removing non-numeric characters
    if (phoneDigits.length <= 4) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userData = {
        email: user.email,
        first_name: firstName,
        company_name: companyName,
        is_buyer: registrationType === "buyer",
      };

      await GenericBackend.post(
        getEnvironmentConstants().REACT_APP_NEW_USER_IN_FIREBASE,
        userData,
        ""
      );

      // Send email verification
      if (user) {
        await sendEmailVerification(user);
        console.log("Verification email sent");
        setIsVerificationSent(true);

        // Debug comment: If verification link was sent but user was not created in the portal, likely causes with likelihood in decending order
        // 1: Token was empty or invalid
        // 2: If token was present and valid, then there must be one of the following info logs in the log explorer

        const token = await user.getIdToken(/* forceRefresh */ true);
        GenericBackend.logger(
          "Website: Verification email sent successfully, for new signed up user",
          "info",
          token
        );
        // Create user in DB
        const formData = {
          email,
          first_name: firstName,
          last_name: lastName,
          type: 0, // type: Normal users(Buyer and sellers) take type 0
          company_name: companyName,
          service_fee: 0,
          is_buyer: registrationType === "buyer",
          user_uuid: user.uid,
          marketing_consent: marketingConsent,
          accept_terms: acceptTerms,
        };

        await GenericBackend.post(
          getEnvironmentConstants().REACT_APP_CREATE_NEW_USER,
          formData,
          token
        );
        GenericBackend.logger(
          "Website: new user creation request sent to FC server",
          "info",
          token
        );
      }
    } catch (error: any) {
      console.log(error);
      setErrorMessage(
        "Somehting went wrong while account creation please contact Support Team: support@freezecarbon.com"
      );
    }
  };

  return (
    <Box sx={{ px: 2, py: 2, width: "100%" }}>
      {!isVerificationSent ? (
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First name"
                variant="outlined"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last name"
                variant="outlined"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Work email address"
                type="email"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            {/* Phone Number */}
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                label="Phone number"
                fullWidth
                defaultCountry="DE" // Default to Germany
                value={phoneNumber}
                onChange={(newValue) => setPhoneNumber(newValue)}
                required
              />
            </Grid>
            {/* Company Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company name"
                variant="outlined"
                required
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            {/* Type of Registration */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="registration-type-label">
                  Type of Registration
                </InputLabel>
                <Select
                  labelId="registration-type-label"
                  id="registration-type"
                  value={registrationType}
                  onChange={(e) => setRegistrationType(e.target.value)}
                  label="Type of Registration"
                  required
                >
                  <MenuItem value="buyer">Carbon credit buyer</MenuItem>
                  <MenuItem value="seller">Carbon project developer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Password */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {/* Confirm Password */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                variant="outlined"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            {/* Terms and Conditions */}
            <Grid item xs={12}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    required
                  />
                }
                label="By clicking agree and join, you agree to Freeze Carbon Terms & Conditions and Privacy Policy."
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    required
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{ display: "inline-block" }}
                    // sx={{ display: "flex", flexDirection: "row" }}
                  >
                    By clicking agree and join, you agree to Freeze Carbon{" "}
                    <Link
                      href="/pao3wdv5n-contract"
                      target="_blank"
                      rel="noopener"
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      href="/data-privacy-en"
                      target="_blank"
                      rel="noopener"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)}
                    name="marketingConsent"
                  />
                }
                label={
                  <Typography variant="body2">
                    I'd like to receive news and marketing emails from Freeze
                    Carbon. You can unsubscribe at any time.
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {/* Agree and Join Button */}
          <Box sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ padding: "10px 0", fontSize: "16px" }}
            >
              Agree and Join
            </Button>
          </Box>
          <div id="recaptcha-container"></div>
        </form>
      ) : (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">
            Thank you for registering for an account. Please go to your email
            inbox (or spam) and comfirm your email address.
          </Typography>
        </Box>
      )}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Box>
  );
};

export default SignUpForm;
