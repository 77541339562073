import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import { FormattedMessage } from "react-intl";
import Partners from "../../../components/Partners";
import { useState } from "react";
import ClientScroller from "ui-component/ClientScroller";
import { Button } from "@mui/material";
import { IconArrowUpCircle } from "@tabler/icons";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ThorstenCard from "ui-component/ThorstenCard";
import Project from "./Project";
import { projectData } from "../../../../../common";
import Map from "./map";

const OurProjects = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2CA4E6",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#E0C2FF",
        light: "#F5EBFF",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#47008F",
      },
    },
  });

  return (
    <div className="testimonial_main">
      <NavBar />

      <Map />

      <div className="content_wrapper">
        <div className="testimonial_reviews our_projects">
          <div className="homepage_project_heading_data_content">
            <div className="homepage_project_heading_data_content_value">
              <FormattedMessage
                values={{ break: <br /> }}
                id="OurProjects_title"
                defaultMessage="We only present carbon projects which actually reduce global warming"
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
              style={{ marginBottom: "0.8em", textTransform: "none" }}
            >
              <FormattedMessage
                id="OurProjects_title_sub"
                defaultMessage="Protect your reputation and contribute to global cooling"
              />
            </div>
          </div>

          <div className="homepage_project_container">
            <div
              className="homepage_project_container_types projects_gap"
              style={{ width: "100%" }}
            >
              {projectData.map((project, index) => (
                <Project
                  key={index} // Use a unique key (like project ID if available)
                  setDisplayModalCallBack={() => setDisplayModal(!displayModal)}
                  data={project} // Pass project dynamically
                />
              ))}
            </div>

            <div className={`Modal ${displayModal ? "Show" : ""}`}>
              <div className="modal_container">
                <div className="modeal_container_content">
                  <div className="modeal_container_content_text">
                    <div className="modal_container_arrow">
                      <IconArrowUpCircle size="30" />{" "}
                      <div>
                        {" "}
                        <FormattedMessage
                          values={{ break: <br /> }}
                          id="learnMoreArrow"
                          defaultMessage="Take action to cool our planet today"
                        />{" "}
                      </div>
                    </div>

                    <div className="learnMoreHeadingStyle">
                      {" "}
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreHeading"
                        defaultMessage="Safe and efficient carbon neutralization"
                      />{" "}
                    </div>
                  </div>
                  <div className="modeal_container_content_button">
                    <div className="learn_more_data">
                      {" "}
                      <FormattedMessage
                        values={{ break: <br /> }}
                        id="learnMoreData"
                        defaultMessage="Take action to cool our planet today"
                      />{" "}
                    </div>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        href="/getting-started#talk-to-expert"
                        style={{
                          minWidth: "320px",
                        }}
                      >
                        {" "}
                        <FormattedMessage
                          values={{ break: <br /> }}
                          id="learnMoreButton1"
                          defaultMessage="Take action to cool our planet today"
                        />{" "}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        href="/getting-started#talk-to-expert"
                        style={{
                          minWidth: "320px",
                        }}
                      >
                        <FormattedMessage
                          values={{ break: <br /> }}
                          id="learnMoreButton2"
                          defaultMessage="Take action to cool our planet today"
                        />{" "}
                      </Button>
                    </ThemeProvider>
                  </div>
                  <button
                    className="Close"
                    onClick={() => setDisplayModal(!displayModal)}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`Overlay ${displayModal ? "Show" : ""}`}
              onClick={() => setDisplayModal(!displayModal)}
            />
          </div>
        </div>

        {/* clients  */}

        <div style={{ marginTop: "3em" }}>
          <ClientScroller />
        </div>

        <ThorstenCard />

        {/* partners  */}

        <div style={{ marginBottom: "3em" }}>
          <Partners />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default OurProjects;
