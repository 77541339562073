const Map = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="bg_image_3"
        style={{
          marginBottom: "3em",
          height: "650px",
          width: "100%",
        }}
      ></div>
    </div>
  );
};

export default Map;
