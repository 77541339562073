import { FormattedMessage } from "react-intl";
import step6 from "assets/images/how_it_works/6.png";

const Quality = () => {
  return (
    <div className="bg_image_1">
      <div className="bg_blur">
        <div
          className="homepage_project_header_container"
          style={{ margin: "3em 0px 1em 0" }}
        >
          <div className="homepage_project_heading navbar_margin_zero">
            <div className="homepage_project_heading_content">
              <FormattedMessage
                id="extramiles"
                defaultMessage="The Extra Mile in Quality"
              />
            </div>
          </div>
        </div>

        <div
          className="homepage_project_container remove_bg_properties"
          style={{ paddingTop: "3em" }}
        >
          <div className="heading_width">
            <div className="homepage_project_header_container">
              <div className="homepage_project_heading_data_content ">
                <div className="homepage_project_heading_data_content_value ">
                  <FormattedMessage
                    id="end_carbon_credit_service"
                    defaultMessage="Only projects with a measurable climate cooling effect."
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
            style={{ marginBottom: "0.8em", textTransform: "none" }}
          >
            <FormattedMessage
              id="services_sub_heading"
              defaultMessage="Protect your reputation and contribute to global cooling"
            />
          </div>
        </div>

        <div
          className="services_banner_container row-reverse services_container_spacing "
          id="quality-analysis"
        >
          <div className="service_banner_data three_steps_data">
            <div className="bullet-point-item add-bullet-gap">
              <div>
                {" "}
                <div className="circle">1</div>
              </div>
              <span className="bullet-text" style={{ opacity: 1 }}>
                {" "}
                <FormattedMessage
                  id="extramilesstep1"
                  defaultMessage="First, we define which project types provide the highest safeguards and most accurate climate impact calculation. Additionally, we look for projects contributing to the United Nations 17 Social Development Goals and support these beyond a climate impact."
                />
              </span>
            </div>
            <div className="bullet-point-item add-bullet-gap">
              <div>
                {" "}
                <div className="circle">2</div>
              </div>
              <span className="bullet-text">
                <FormattedMessage
                  id="extramilesstep2"
                  defaultMessage="Secondly, we screen all projects in these categories using external sources. For this, we review the project’s certification document and related climate impact calculations, as well as publicly available information."
                />
              </span>
            </div>
            <div className="bullet-point-item add-bullet-gap">
              <div>
                {" "}
                <div className="circle">3</div>
              </div>
              <span className="bullet-text">
                <FormattedMessage
                  id="extramilesstep3"
                  defaultMessage="Thirdly, we conduct a detailed quality analysis above and beyond the requirements of the certification standard to ensure only the best of the best projects are listed on Freeze Carbon. This is the most extensive part and most vital to our work as we focus on quality over quantity."
                />
              </span>
            </div>
          </div>

          <div className="service_banner_image_reverse three_steps_image">
            <img src={step6} className="services_image_width_90" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quality;
