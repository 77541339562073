import React, { useEffect, useRef, useState } from "react";

import { Link, Link as RouterLink } from "react-router-dom";
import {
  IconArrowDown,
  IconArrowRight,
  IconHeartHandshake,
  IconPlus,
} from "@tabler/icons";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Partners from "../../components/Partners";
import PartnersV1 from "../../components/PartnersV1";

import gs from "assets/images/new-icons/gs.svg";
import iso from "assets/images/new-icons/iso.svg";
import dnv from "assets/images/new-icons/dnv.svg";
import pe from "assets/images/new-icons/pe.svg";
import ebc from "assets/images/new-icons/csi.png";
import { FormattedMessage } from "react-intl";
import pccs from "assets/images/new-icons/pccs.svg";
import qarm from "assets/images/new-icons/qarm.svg";
import cep from "assets/images/new-icons/cep.svg";
import ProjectScroller from "ui-component/ProjectScroller";

import ViewsContactLong from "views/hub-spot/contact-long";
import ClientScroller from "ui-component/ClientScroller";
import ThorstenCard from "ui-component/ThorstenCard";

import Hero from "./Hero";
import Quality from "./Quality";
import Standards from "./Standards";
import MissionProjects from "./MissionProjects";
import ExamleProjects from "./ExampleProjects";
import References from "./References";

const HomePage = () => {
  const handleServices1 = () => {};

  const containerRef = useRef<HTMLDivElement>(null); // Specify the type of ref
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeDot, setActiveDot] = useState(0);

  const handleScrollTo = (index) => {
    if (containerRef.current) {
      // Check if containerRef.current is not null
      const scrollAmount = containerRef.current.offsetWidth * index;
      console.log(scrollAmount);
      containerRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
      setActiveDot(index);
      setScrollPosition(scrollAmount);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeDot + 1) % 3; // Assuming there are 3 sections to scroll through
      handleScrollTo(nextIndex);
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [activeDot]);

  return (
    <div>
      <NavBar />

      <div style={{ marginBottom: "3em" }}>
        <Hero />
      </div>

      <div className="content_wrapper">
        <div style={{ marginBottom: "3em" }}>
          <ClientScroller />
        </div>

        <div style={{ marginBottom: "3em" }}>
          <Quality />
        </div>

        <Standards />

        <MissionProjects />

        <References />

        <ExamleProjects />

        {/* services */}

        <div className="homepage_project_container" style={{ padding: "0" }}>
          <div className="heading_width">
            {" "}
            <div className="homepage_project_header_container">
              <div
                className="homepage_project_heading"
                style={{ marginBottom: "3em", marginTop: 0 }}
              >
                <div className="homepage_project_heading_content">
                  <FormattedMessage id="solution" defaultMessage="Services" />
                </div>
              </div>
              <div className="homepage_project_heading_data_content">
                <div className="homepage_project_heading_data_content_value">
                  {" "}
                  <FormattedMessage
                    id="servicesTitle"
                    defaultMessage="End-to-end{break}carbon credit service"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
              <div
                className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
                style={{ marginBottom: "0.8em", textTransform: "none" }}
              >
                <FormattedMessage
                  id="servicesSubTitle"
                  defaultMessage="Protect your reputation and contribute to global cooling"
                />
              </div>
            </div>
          </div>
          <div
            className="homepage_project_container_types container_spacing_mobile"
            style={
              {
                // justifyContent: "space-between",
              }
            }
          >
            <div className="homepage_how_it_works">
              <RouterLink
                to="/services#quality-analysis"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="homepage_project_container_types_row">
                  <div className="homepage_project_container_types_row_column homepage_box_height">
                    <div className="service_banner_image homepage_image">
                      <img src={qarm} className="home_image_width" />
                    </div>
                    <div
                      className="homepage_project_container_types_row_column_heading1"
                      style={{ fontSize: "1.2em" }}
                    >
                      <FormattedMessage
                        id="quality_analysis_risk_mitigation"
                        defaultMessage="Quality analysis{break} & risk mitigation"
                        values={{ break: <br /> }}
                      />
                    </div>
                  </div>
                </div>
              </RouterLink>

              <RouterLink
                to="/services#cost-effective"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="homepage_project_container_types_row">
                  <div className="homepage_project_container_types_row_column homepage_box_height">
                    <div className="service_banner_image homepage_image">
                      <img src={cep} className="home_image_width" />
                    </div>
                    <div
                      className="homepage_project_container_types_row_column_heading1"
                      style={{ fontSize: "1.2em" }}
                    >
                      <FormattedMessage
                        id="cost_effective_procurement"
                        defaultMessage="Cost-effective{break} procurement"
                        values={{ break: <br /> }}
                      />
                    </div>
                  </div>
                </div>
              </RouterLink>

              <RouterLink
                to="/services#personalized-strategy"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="homepage_project_container_types_row">
                  <div className="homepage_project_container_types_row_column homepage_box_height">
                    <div className="service_banner_image homepage_image">
                      <img src={pccs} className="home_image_width" />
                    </div>
                    <div
                      className="homepage_project_container_types_row_column_heading1"
                      style={{ fontSize: "1.2em" }}
                    >
                      <FormattedMessage
                        id="personalized_carbon_credit_strategy"
                        defaultMessage="Personalized carbon credit strategy"
                      />
                    </div>
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>

          <div className="homepage_project_container_action center">
            <a
              href="#register-free-account"
              className="homepage_appbar_nav_get_started"
            >
              <FormattedMessage
                id="exploreProjects"
                defaultMessage="Start now"
              />
            </a>
          </div>
        </div>

        <section id="register-free-account"></section>

        <div className="getting_started_boxes ">
          <section
            className="getting_started_box getting-started-form"
            id="register-free-account"
          >
            <div className="getting_started_box_content"></div>
            <div className="homepage_project_heading_data_content">
              <div
                className="homepage_project_heading_data_content_value"
                // style={{ width: "1100px" }}
                style={{ marginTop: "2em" }}
              >
                <FormattedMessage
                  id="gettingStartedHeader"
                  defaultMessage="Sign up to get access to our carbon projects."
                  values={{ break: <br /> }}
                />
                {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
              </div>
            </div>
            <div
              style={{ marginTop: "2em" }}
              className="getting_started_contact_form"
            >
              <ViewsContactLong />
            </div>
          </section>

          <ThorstenCard />
        </div>

        <PartnersV1 />

        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
