import { IconArrowDown, IconArrowUp } from "@tabler/icons";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import NavBar from "../components/NavBar";
import { getLocales } from "../../../react-native-localize";

const FAQ: React.FC = () => {
  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;
  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const seoData = {
    "en-US": {
      title: "Carbon Credit FAQ: Your Questions Answered | Freeze Carbon",
      description:
        "Everything you need to know about carbon credits, the voluntary carbon market, carbon removal, and net-zero emissions. Expert insights from Freeze Carbon.",
    },
    "de-DE": {
      title: "CO₂-Zertifikate FAQ: Antworten auf Ihre Fragen | Freeze Carbon",
      description:
        "Alles, was Du über CO₂-Zertifikate, den freiwilligen Kohlenstoffmarkt, Carbon Removal und Netto-Null-Emissionen wissen musst. Expertenwissen von Freeze Carbon.",
    },
  };

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData[languageToUse].title}</title>
        <meta name="description" content={seoData[languageToUse].description} />
      </Helmet>

      <div className="faq-container">
        <NavBar />
        <div className="content-wrapper" style={{ marginTop: "3em" }}>
          <h1 style={{ lineHeight: `1.2em` }}>
            <FormattedMessage
              id="faqTitle"
              defaultMessage="Frequently Asked Questions (FAQ)"
            />
          </h1>
          <div className="faq-list">
            {Array.from({ length: 7 }, (_, index) => (
              <div key={index} className="faq-item">
                <button
                  className="faq-question"
                  onClick={() => toggleFAQ(index)}
                >
                  <FormattedMessage
                    id={`faqQuestion${index}`}
                    defaultMessage={`Question ${index + 1}`}
                  />
                  {openIndex === index ? <IconArrowUp /> : <IconArrowDown />}
                </button>
                {openIndex === index && (
                  <div className="faq-answer fade-in">
                    <FormattedMessage
                      id={`faqAnswer${index}`}
                      defaultMessage={`Answer ${index + 1}`}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;

/* SCSS File - FAQ.scss */
