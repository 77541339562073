import React from "react";
import { FormattedMessage } from "react-intl";
import { FlagIconCode, FlagIcon } from "react-flag-kit";

interface ProjectDetailProps {
  classDefaultMessage?;
  classMessageId?;
  messageId?: string;
  messageDefault?: string;
  icon?: string[];
  iconWidth?: string;
}

const ProjectDetail: React.FC<ProjectDetailProps> = ({
  classDefaultMessage,
  classMessageId,
  messageId,
  messageDefault,
  icon = [],
  iconWidth = "40px",
}) => (
  <div>
    <div className="type_name">
      <FormattedMessage
        id={classMessageId}
        defaultMessage={classDefaultMessage}
        values={{ break: <br /> }}
      />
    </div>
    {messageId && messageDefault ? (
      <div className="type_description">
        <FormattedMessage
          id={messageId}
          defaultMessage={messageDefault}
          values={{ break: <br /> }}
        />
        <div className="sdgs_icons">
          {icon.map((iconSrc, index) => (
            <img
              key={index}
              src={iconSrc}
              style={{ width: iconWidth }}
              alt=""
              className="project__icon"
            />
          ))}
        </div>
      </div>
    ) : (
      icon.length > 0 && (
        <div className="sdgs_icons">
          {icon.map((iconSrc, index) => (
            <img
              key={index}
              src={iconSrc}
              style={{ width: iconWidth }}
              alt=""
              className="project__icon"
            />
          ))}
        </div>
      )
    )}
  </div>
);

const ProjectData: React.FC<{ subSections: ProjectDetailProps[] }> = ({
  subSections,
}) => (
  <div className="projects_data">
    {subSections.map((detail, index) => (
      <ProjectDetail key={index} {...detail} />
    ))}
  </div>
);

const Project: React.FC<{
  setDisplayModalCallBack: () => void;
  data: {
    titleId: string;
    titleDefaultMessage: string;
    imageSrc: string;
    code: string;
    subSections: ProjectDetailProps[];
  };
}> = ({ setDisplayModalCallBack, data }) => {
  const {
    titleId,
    titleDefaultMessage,
    imageSrc,
    code,
    subSections = [],
  } = data;
  return (
    <div
      className="homepage_project_container_types_row1"
      // style={{ marginBottom: "5em" }}
    >
      <div className="homepage_project_container_types_row_column our_projects_box homepage_box_height">
        <div className="service_banner_image projects_image">
          <img src={imageSrc} className="image_width1" />{" "}
        </div>
        <div className="homepage_project_container_types_row_column_heading3">
          <FormattedMessage
            values={{ break: <br /> }}
            id={titleId}
            defaultMessage={titleDefaultMessage}
          />
          <FlagIcon
            code={code as FlagIconCode}
            style={{ width: "30px", height: "20px" }}
          />
        </div>

        <ProjectData subSections={subSections} />

        <div className="button_learn_more">
          <div
            className="homepage_appbar_nav_get_started_learn_more"
            onClick={setDisplayModalCallBack}
          >
            <FormattedMessage
              values={{ break: <br /> }}
              id="learnMoreButton"
              defaultMessage="Learn more"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
